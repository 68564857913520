import React from "react"

import SEO from "../components/seo"
import loadable from '@loadable/component'
import LayoutsComponent from '../layouts/LayoutsComponent';
import { AttachMoney, Home, Check, HowToReg as HowToRegIcon } from '@material-ui/icons';
import LandingBroker from "../components/pages/afi/LandingBroker";

const iconos = { 1: <Home />, 2: <HowToRegIcon />, 3: <Check />, 4: <AttachMoney /> }

const LandingBrokerPage = ({ pageContext, location }) => (

  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    companyName={pageContext.company}
    gradient
    iconos={iconos}
    pageContext={pageContext}
    location={location}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <LandingBroker pageContext={pageContext} />
      </>
    )}
  />

)

export default LandingBrokerPage